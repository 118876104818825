import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import emailjs from '@emailjs/browser'
import { useForm } from 'react-hook-form'
import Map, {
  Marker,
  Popup,
  ScaleControl,
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
} from 'react-map-gl'
import { mapBoxToken, mapCoordinates } from '../../utils/constants'
import { getErrorMessage } from '../../utils/helpers'
import 'mapbox-gl/dist/mapbox-gl.css'

const ContactUs = () => {
  const formRef = useRef()
  const {
    register,
    reset,
    setFocus,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  })
  const [showPopup, setShowPopup] = useState(true)
  const [submittingForm, setSubmittingForm] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setFocus('name')
    }, 1000)
  }, [setFocus])

  const sendEmail = (e) => {
    e.preventDefault()
    setSubmittingForm(true)

    emailjs
      .sendForm('service_6hsmbtj', 'template_1gxa7ik', formRef.current, {
        publicKey: 'AdFP18Kmaw1URSXMB',
      })
      .then(
        () => {
          toast.success('Thanks for your time, we will contact you soon!', {
            position: 'top-right',
          })
          reset()
        },
        (error) => {
          toast.error(error.text, {
            position: 'top-right',
          })
        }
      )
      .finally(() => {
        setSubmittingForm(false)
      })
  }
  return (
    <>
      <div className="container-fluid bg-primary py-5 bg-header-contact">
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">Contact Us</h1>
            <Link
              to="/"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              Home
            </Link>
            <i className="fas fa-long-arrow-alt-right text-white px-2"></i>
            <Link
              to="/contact"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: '1000px' }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
            <h1 className="mb-0">If You Have Any Query, Feel Free To Ask</h1>
          </div>
          <div className="row g-5 py-3 mb-5">
            <div className="col-lg-4">
              <div className="d-flex wow fadeIn" data-wow-delay="0.1s">
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: '60px', height: '60px' }}
                >
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h5 className="text-primary mb-0">
                    <a
                      className="link-offset-1 link-underline-opacity-25 link-underline-opacity-100-hover"
                      href="tel:9671056058"
                    >
                      9671056058
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex wow fadeIn" data-wow-delay="0.4s">
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: '60px', height: '60px' }}
                >
                  <i className="fa fa-envelope-open text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Email to get free quote</h5>
                  <h5 className="text-primary text-break mb-0">
                    alutechindiaconnect@gmail.com
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex wow fadeIn" data-wow-delay="0.8s">
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: '60px', height: '60px' }}
                >
                  <i className="fa fa-map-marker-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Visit our office</h5>
                  <h5 className="text-primary mb-0">
                    HSIIDC Industrial Area, Samalkha
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
              <form ref={formRef} onSubmit={sendEmail}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <input
                      id="nameInput"
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Name"
                      name="name"
                      {...register('name', {
                        required: true,
                        minLength: {
                          value: 3,
                          message: 'Length must be 3 or more',
                        },
                      })}
                      style={{ height: '55px' }}
                    />
                    {errors.name && getErrorMessage(errors.name.message)}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your contact"
                      name="phone"
                      min="1"
                      {...register('phone', {
                        required: true,
                        minLength: {
                          value: 10,
                          message: 'Phone number should have 10 digits',
                        },
                        maxLength: {
                          value: 10,
                          message: 'Phone number should have 10 digits',
                        },
                      })}
                      style={{ height: '55px' }}
                    />
                    {errors.phone && getErrorMessage(errors.phone.message)}
                  </div>
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Email"
                      name="email"
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Entered value does not match email format',
                        },
                      })}
                      style={{ height: '55px' }}
                    />
                    {errors.email && getErrorMessage(errors.email.message)}
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Subject"
                      name="subject"
                      {...register('subject', { required: true })}
                      style={{ height: '55px' }}
                    />
                    {errors.subject && getErrorMessage(errors.subject.message)}
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control border-0 bg-light px-4 py-3"
                      rows="4"
                      placeholder="Message"
                      name="message"
                      {...register('message', { required: true })}
                    ></textarea>
                    {errors.message && getErrorMessage(errors.message.message)}
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      disabled={!isValid || submittingForm}
                    >
                      {submittingForm ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        'Get Free Quote'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
              <Map
                reuseMaps
                mapboxAccessToken={mapBoxToken}
                mapLib={import('mapbox-gl')}
                initialViewState={{
                  longitude: mapCoordinates.lng,
                  latitude: mapCoordinates.lat,
                  zoom: 12,
                  bearing: 0,
                  pitch: 0,
                }}
                style={{ minHeight: 400 }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                attributionControl={false}
              >
                <ScaleControl />
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <Marker
                  longitude={mapCoordinates.lng}
                  latitude={mapCoordinates.lat}
                  anchor="bottom"
                  onClick={(e) => {
                    e.originalEvent.stopPropagation()
                    setShowPopup(true)
                  }}
                >
                  <i
                    className="fas fa-map-marker-alt fs-2 text-primary"
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '10px',
                    }}
                  ></i>
                </Marker>
                {showPopup && (
                  <Popup
                    longitude={mapCoordinates.lng}
                    latitude={mapCoordinates.lat}
                    anchor="bottom"
                    onClose={() => setShowPopup(false)}
                    className="p-3"
                  >
                    {getInfo()}
                  </Popup>
                )}
              </Map>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const getInfo = () => (
  <a
    href="https://g.co/kgs/y2uoDxB"
    target="_blank"
    rel="noreferrer"
    className="d-block ps-1 pe-2 link-warning link-underline-opacity-0"
  >
    <h3 className="text-primary">Alutech India</h3>
    <h6 className="fw-normal mb-0 text-light-yellow">
      Ward1, HSIIDC Industrial Area,
      <br />
      Samalkha, Haryana, 132101
    </h6>
  </a>
)

export default ContactUs
