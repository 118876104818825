export const windowsData = [
  {
    title: '2900 Basic',
    desc: 'Design that is meant for small to medium-sized windows, economical & budget-friendly slim sliding system.',
    imageUrl: '/img/windows/2900-slim.jpg',
    technicalSpecifications: [
      '3 Track Frame Width - 100.3 MM',
      'Frame Height - 50.5 MM',
      'Shutter Width - 65 MM',
      'Section Cutting - 45 Deg',
      'Rail Track - Aluminium',
    ],
  },
  {
    title: '3100 Slim',
    desc: 'Suitable for medium-sized windows, this system balances aesthetics with simplicity.',
    imageUrl: '/img/windows/3100-slim.jpg',
    technicalSpecifications: [
      '3 Track Frame Width - 120.45 MM',
      'Frame Height - 47.5 MM',
      'Shutter Width - 65/58 MM',
      'Section Cutting - 90 Deg',
      'Rail Track - Aluminium',
    ],
  },
  {
    title: '3200 Slim',
    desc: 'Versatile sliding system with extra strength due to its system design to meet higher requirements.',
    imageUrl: '/img/windows/3200-slim.jpg',
    technicalSpecifications: [
      '3 Track Frame Width - 114 MM',
      'Frame Height - 50 MM',
      'Shutter Width - 65 MM',
      'Section Cutting - 45 Deg',
      'Rail Track - Aluminium',
    ],
  },
  {
    title: '5mm Ultra Slim',
    desc: 'Extra strong window with modified sections, useful to meet various heights with its multiple shutter sizes.',
    imageUrl: '/img/windows/3500-slim.jpg',
    technicalSpecifications: [
      '3 Track Frame Width - 149/142 MM',
      'Frame Height - 31/41 MM',
      'Shutter Width - 80/70 MM',
      'Section Cutting - 90 Deg',
      'Rail Track - Stainless Steel 316',
    ],
  },
  {
    title: '3500 High End',
    desc: 'The system that Enhances your space with its slim interlock design, easy operational with its smooth functionality.',
    imageUrl: '/img/windows/3500-high-end.jpg',
    technicalSpecifications: [
      '3 Track Frame Width - 114.45 MM',
      'Frame Height - 40 MM',
      'Shutter Width - 68 MM',
      'Section Cutting - 45 Deg',
      'Rail Track - Aluminium',
    ],
  },
  {
    title: 'Ultra Slim',
    desc: 'A system having slimmest interlock with an innovative design that takes aluminum window standard to another level.',
    imageUrl: '/img/windows/ultra-slim.jpg',
    technicalSpecifications: [
      '3 Track Frame Width - 169 MM',
      'Frame Height - 28 MM',
      'Shutter Width - 65 MM',
      'Section Cutting - 90 Deg',
      'Rail Track - Stainless Steel 316',
    ],
  },
  {
    title: 'Super Slim',
    desc: 'The strongest system for taller heights yet with a slimmer interlock, it’s an extra premium system with super performance.',
    imageUrl: '/img/windows/super-slim.jpg',
    technicalSpecifications: [
      '2 Track Frame Width - 125 MM',
      'Frame Height - 42 MM',
      'Shutter Width - 65 MM',
      'Section Cutting - 45 Deg',
      'Rail Track - Aluminium',
    ],
  },
]

export const doorsData = [
  {
    title: 'R40 Casement Series',
    desc: 'The system offers inside or outside openings with proper sound insulation and air-tight seal. Ideal for tight spaces or privacy-focused areas like ventilation.',
    imageUrl: '/img/doors/r-40-banner.jpg',
    technicalSpecifications: [
      'Outer Frame - 40x40 MM',
      'Mullion - 54x40 MM',
      'Outside Shutter - 70x40 MM',
      'Glass Shutter - 5 MM to 24 MM',
      'Section Cutting - 45 Deg',
    ],
  },
  {
    title: 'R50 Door Series',
    desc: 'The system used for doors, offers durability, insulation, sleek aesthetics, low maintenance, and enhanced security due to its robust build and design.',
    imageUrl: '/img/doors/r-50-banner.jpg',
    technicalSpecifications: [
      'Outer Frame - 50x46 MM',
      'Mullion - 66x50 MM',
      'Outside Shutter - 78.5x58 MM',
      'Inside Opening Shutter - 95.9x58 MM',
      'Section Cutting - 45 Deg',
    ],
  },
]
