import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import emailjs from '@emailjs/browser'
import { useForm, SubmitHandler } from 'react-hook-form'
import { getErrorMessage } from '../../utils/helpers'

const Footer = () => {
  const formRef = useRef()
  const [submittingForm, setSubmittingForm] = useState(false)
  const {
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  })

  const sendEmail = (e) => {
    e.preventDefault()
    setSubmittingForm(true)

    emailjs
      .sendForm('service_6hsmbtj', 'template_sptd14f', formRef.current, {
        publicKey: 'AdFP18Kmaw1URSXMB',
      })
      .then(
        () => {
          toast.success('Thanks for your time, we will contact you soon!', {
            position: 'top-right',
          })
          reset()
        },
        (error) => {
          toast.error(error.text, {
            position: 'top-right',
          })
        }
      )
      .finally(() => {
        setSubmittingForm(false)
      })
  }
  return (
    <>
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center h-100 bg-primary p-4">
                <Link to="/" className="navbar-brand">
                  <h1 className="m-0 text-white text-center">Alutech India</h1>
                </Link>
                <p className="mt-3 mb-4">
                  We are celebrating as a brand for our aluminium sash window,
                  entrance doors, and other building materials all over India.
                  <br />
                  <br />
                  <span className="text-dark">
                    Please share your contact, we will call you to answer your
                    queries.
                  </span>
                </p>
                <form ref={formRef} onSubmit={sendEmail} className="w-100">
                  <div className="input-group flex-nowrap">
                    <input
                      type="number"
                      className="form-control border-white p-3 focus-ring-opacity-0 focus-ring-width-0"
                      placeholder="Your contact"
                      name="user_phone"
                      min="1"
                      {...register('user_phone', {
                        required: true,
                        minLength: {
                          value: 10,
                          message: 'Digits should be 10',
                        },
                        maxLength: {
                          value: 10,
                          message: 'Digits should be 10',
                        },
                      })}
                    />
                    <button
                      className="btn btn-dark py-3 px-3"
                      type="submit"
                      disabled={!isValid || submittingForm}
                    >
                      {submittingForm ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="far fa-paper-plane"></i>
                      )}
                    </button>
                  </div>
                  {errors.user_phone &&
                    getErrorMessage(errors.user_phone.message)}
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-7 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2"></i>
                    <p className="mb-0">HSIIDC Industrial Area, Samalkha</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">alutechindiaconnect@gmail.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0 text-primary">
                      <a
                        className="link-offset-1 link-underline-opacity-25 link-underline-opacity-100-hover"
                        href="tel:9671056058"
                      >
                        9671056058
                      </a>
                      ,&nbsp;
                      <a
                        className="link-offset-1 link-underline-opacity-25 link-underline-opacity-100-hover"
                        href="tel:9050400964"
                      >
                        9050400964
                      </a>
                    </p>
                  </div>
                  <div className="d-flex mt-4">
                    <a
                      className="btn btn-primary btn-square me-2 px-0"
                      href="#"
                    >
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-primary btn-square me-2 px-0"
                      href="#"
                    >
                      <i className="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-primary btn-square me-2 px-0"
                      href="#"
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                    <a
                      className="btn btn-primary btn-square me-2 px-0"
                      href="#"
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
                    <a className="btn btn-primary btn-square px-0" href="#">
                      <i className="fab fa-youtube fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link to="/" className="text-light mb-2">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Home
                    </Link>
                    <Link to="/about" className="text-light mb-2">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      About Us
                    </Link>
                    <Link to="/products/windows" className="text-light mb-2">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Products
                    </Link>
                    <Link to="/testimonials" className="text-light mb-2">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Testimonials
                    </Link>
                    <Link to="/contact" className="text-light mb-2">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: '#061429' }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: '75px' }}
              >
                <p className="mb-0">
                  &copy;&nbsp;
                  <Link to="/" className="m-0 text-white">
                    Alutech India
                  </Link>
                  &nbsp; All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
