import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import WOW from 'wowjs'
import { MapProvider } from 'react-map-gl'
import { Spinner, Topbar, Navbar, BackToTop, Footer } from './common'
import AppRoutes from './routes'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'

function App() {
  const [showSpinner, setShowSpinner] = useState(true)

  // fake loading
  setTimeout(() => {
    setShowSpinner(false)
  }, 1000)

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init()
  }, [])

  return (
    <MapProvider>
      <div className="App">
        {showSpinner && <Spinner />}
        <Topbar />
        <Navbar />
        <AppRoutes />
        <Footer />
        <BackToTop />
        <ToastContainer />
      </div>
    </MapProvider>
  )
}

export default App
