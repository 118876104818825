import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'

const Testimonials = () => {
  const carouselOptions = {
    smartSpeed: 1500,
    dots: true,
    loop: true,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  }

  return (
    <>
      <div className="container-fluid bg-primary py-5 bg-header-testimonials">
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">
              Testimonials
            </h1>
            <Link
              to="/"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              Home
            </Link>
            <i className="fas fa-long-arrow-alt-right text-white px-2"></i>
            <Link
              to="/testimonials"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              Testimonials
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-4 mx-auto"
            style={{ maxWidth: '1000px' }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Endorsements
            </h5>
            <h1 className="mb-0">What Our Clients Say About Our Services</h1>
          </div>

          <OwlCarousel
            className="owl-theme testimonial-carousel wow fadeInUp pt-5"
            data-wow-delay="0.6s"
            {...carouselOptions}
          >
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="/img/sachin-saini.jpg"
                  style={{ width: '60px', height: '60px' }}
                  alt="sachin saini"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Sachin Saini</h4>
                  <small className="text-uppercase">Marketing Executive</small>
                </div>
              </div>
              <div className="message pt-4 pb-5 px-5">
                Innovative and improvised products compare to existing options
                in the market. They seem to be professional in there business
                and ready to answer all your questions.
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="/img/architect.jpg"
                  style={{ width: '60px', height: '60px' }}
                  alt="ankit deshwal"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Ankit Deshwal</h4>
                  <small className="text-uppercase">B.arch & M.plan</small>
                </div>
              </div>
              <div className="message pt-4 pb-5 px-5">
                Elegance meets quality! Quality of the material was good and
                delivery was prompt. No follow up needed, overall a completely
                hassle free experience.
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="/img/ravi-ahlawat.jpg"
                  style={{ width: '60px', height: '60px' }}
                  alt="ravi ahlawat"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Ravi Ahlawat</h4>
                  <small className="text-uppercase">
                    Director of Home Fashion
                  </small>
                </div>
              </div>
              <div className="message pt-4 pb-5 px-5">
                I have to say this, the company does take their work very
                seriously. The presentation that their sales team gave was very
                informative and insightful.
              </div>
            </div>
            <div className="testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  className="img-fluid rounded"
                  src="/img/ombir-rana.jpg"
                  style={{ width: '60px', height: '60px' }}
                  alt="ombir rana"
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">Ombir Rana</h4>
                  <small className="text-uppercase">
                    Manager at Jindal Steel
                  </small>
                </div>
              </div>
              <div className="message pt-4 pb-5 px-5">
                Special thanks to Alutech India for their prompt service for the
                installation. We are happy with the quality of products offered
                and after sales services delivered.
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  )
}

export default Testimonials
