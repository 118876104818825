import React from 'react'

const BackToTop = () => {
  return (
    <a
      href="#"
      className="btn btn-lg btn-primary btn-lg-square rounded back-to-top text-center px-0"
    >
      <i className="bi bi-arrow-up"></i>
    </a>
  )
}

export default BackToTop
