import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { NotFound } from './common'
import {
  Home,
  About,
  Products,
  Testimonials,
  ProductsGrid,
  ContactUs,
} from './pages'

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="products" element={<Products />}>
        <Route
          path="windows"
          element={<ProductsGrid productType="windows" />}
        />
        <Route path="doors" element={<ProductsGrid productType="doors" />} />
      </Route>
      <Route path="testimonials" element={<Testimonials />} />
      <Route path="contact" element={<ContactUs />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
