import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
      <div className="container-fluid bg-primary py-5 bg-header-about">
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">About Us</h1>
            <Link
              to="/"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              Home
            </Link>
            <i className="fas fa-long-arrow-alt-right text-white px-2"></i>
            <Link
              to="/about"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              About
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  Know Us Better
                </h5>
                <h1 className="mb-0">
                  The Best Service With 5 Years of Experience
                </h1>
              </div>
              <p className="mb-4">
                We offer the best quality System Windows & doors, Partition,
                Railing etc. Since 2020 We are manufacturing all these
                therefore, we understand that what quality needs to be performed
                & look like onsite. Our work possesses outstanding consistency,
                durability, and quality.
              </p>

              <p className="mb-4">
                We believe in giving our persistent efforts in upgrading
                quality, designing extraordinary, and forming brilliant
                relationship with our clients.
              </p>

              <p className="mb-4">
                We give important adherence to sustainability in our processing
                and management. We consider ourselves accountable for social,
                economical, & environmental responsibilities.
              </p>

              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Top Quality
                    Material
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>
                    Professional Staff
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Excellent
                    Service
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Fair Prices
                  </h5>
                </div>
              </div>

              <div
                className="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: '60px', height: '60px' }}
                >
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">
                    <a
                      className="link-offset-1 link-underline-opacity-25 link-underline-opacity-100-hover"
                      href="tel:9671056058"
                    >
                      9671056058
                    </a>
                    ,&nbsp;
                    <a
                      className="link-offset-1 link-underline-opacity-25 link-underline-opacity-100-hover"
                      href="tel:9050400964"
                    >
                      9050400964
                    </a>
                  </h4>
                </div>
              </div>
              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
              >
                Request A Quote
              </Link>
            </div>
            <div className="col-lg-5" style={{ minHeight: '500px' }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="/img/home.jpg"
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </div>
          <hr className="my-5" />
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">Our Team</h5>
            <h1 className="mb-0">Combined effort counts for success.</h1>
          </div>
          <div className="row g-5">
            <div className="col-md-4 wow slideInUp" data-wow-delay="0.1s">
              <div className="blog-item bg-light rounded overflow-hidden">
                <div className="blog-img position-relative overflow-hidden">
                  <img className="img-fluid" src="/img/mohit.jpg" alt="" />
                  {/* <a
                    className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                    href=""
                  >
                    Mahesh Sharma
                  </a> */}
                </div>
                <div className="p-4">
                  <div className="d-flex mb-3">
                    <small className="me-3">
                      <i className="far fa-user text-primary me-2"></i>Mahesh
                      Sharma
                    </small>
                    <small>
                      <i className="far fa-calendar-alt text-primary me-2"></i>
                      24 Sep, 1993
                    </small>
                  </div>
                  <h4 className="mb-3">Founder</h4>
                  <p>B.Tech. Mechanical</p>
                  <p>
                    8<sup>+</sup> years of experience in Aluminium Industry
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
