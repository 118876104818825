import React from 'react'

export const getErrorMessage = (msg) => (
  <small className="text-danger">{msg || 'This field is required'}</small>
)

export const scrollToTargetAdjusted = ({elementId, headerOffset = 0})=>{
  const element = document.getElementById(elementId);
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
}
