import React, { useState, useEffect, createContext } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { scrollToTargetAdjusted } from '../../utils/helpers.js'

export const ProductContext = createContext()

const Products = () => {
  const { pathname } = useLocation()
  const pathList = pathname
    .split('/')
    .filter((el) => el.replace(/(\r\n|\n|\r)/gm, ''))
  const currentProduct = pathList[pathList.length - 1]
  const [productType, setProductType] = useState(currentProduct)

  useEffect(() => {
    setProductType(currentProduct)
  }, [currentProduct])

  return (
    <>
      <div className="container-fluid bg-primary py-5 bg-header-products">
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">Products</h1>
            <Link
              to="/"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              Home
            </Link>
            <i className="fas fa-long-arrow-alt-right text-white px-2"></i>
            <Link
              to="/products"
              className="h6 text-white link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            >
              Products
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container pt-5">
          <div
            className="section-title text-center position-relative pb-3 my-5 mx-auto"
            style={{ maxWidth: '1000px' }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Offerings</h5>
            <h1 className="mb-0">
              We have elevated residential and commercial spaces through our
              dedication to excellence.
            </h1>
          </div>
          <div className="service-items row g-5 py-5">
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item windows bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa fa-window-restore text-white"></i>
                </div>
                <div className="card mt-auto p-4">
                  <h4 className="mb-3">Windows</h4>
                  <p className="m-0">
                    Windows that are highly durable, corrosion-resistant, and
                    immune to dust, drought, and water intrusion.
                  </p>
                </div>

                <Link
                  to="/products/windows"
                  className="btn btn-lg btn-primary rounded"
                  onClick={() => {setProductType('windows'); scrollToTargetAdjusted({elementId: "productGrid", headerOffset: 100});}}
                >
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item doors bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa fa-door-open text-white"></i>
                </div>
                <div className="card mt-auto p-4">
                  <h4 className="mb-3">Doors</h4>
                  <p className="m-0">
                    Your dream home can transform into a haven of elegance and
                    security with the right aluminium doors.
                  </p>
                </div>

                <Link
                  to="/products/doors"
                  className="btn btn-lg btn-primary rounded"
                  onClick={() => {setProductType('doors');scrollToTargetAdjusted({elementId: "productGrid", headerOffset: 100});}}
                >
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div id="productGrid">
          <ProductContext.Provider value={productType}>
            {productType && <Outlet />}
          </ProductContext.Provider>
          </div>
          <div className="wow zoomIn" data-wow-delay="0.9s">
            <div className="position-relative shadow border border-secondary bg-light rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
              <h3 className="mb-3 text-light-yellow">Call Us For Quote</h3>
              <p className="mb-3">
                Always looking ahead to our next exciting project.
              </p>
              <p className="h2 text-primary">
                <a
                  className="link-underline link-offset-2 link-offset-3-hover link-underline-opacity-0 link-underline-opacity-75-hover"
                  href="tel:9671056058"
                >
                  9671056058
                </a>
                ,&nbsp;
                <a
                  className="link-underline link-offset-2 link-offset-3-hover link-underline-opacity-0 link-underline-opacity-75-hover"
                  href="tel:9050400964"
                >
                  9050400964
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Products
