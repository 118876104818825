import { useLocation, useParams } from 'react-router-dom'

const useCurrentPath = () => {
  let { pathname } = useLocation()
  const params = useParams()
  return Object.entries(params).reduce((path, [key, value]) => {
    return path.replace(`/${value}`, `/:${key}`)
  }, pathname)
}

export default useCurrentPath
