import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import useCurrentPath from '../../hooks/useCurrentPath'

const Navbar = () => {
  const currentPath = useCurrentPath()
  return (
    <div className="container-fluid position-relative p-0">
      <nav className="navbar navbar-expand-lg navbar-dark px-3 py-3 py-lg-0">
        <Link to="/" className="navbar-brand p-0">
          <img src="/img/alutech-india-logo.png" height="80" alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <Link
              to="/"
              className={cn(
                'nav-item nav-link',
                currentPath === '/' && 'active'
              )}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={cn(
                'nav-item nav-link',
                currentPath.includes('/about') && 'active'
              )}
            >
              About
            </Link>
            <Link
              to="/products/windows"
              className={cn(
                'nav-item nav-link',
                currentPath.includes('/products') && 'active'
              )}
            >
              Products
            </Link>
            <Link
              to="/testimonials"
              className={cn(
                'nav-item nav-link',
                currentPath.includes('/testimonials') && 'active'
              )}
            >
              Testimonials
            </Link>
            {/* <div className="nav-item dropdown">
              <Link
                to=""
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Blog
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/blogGrid" className="dropdown-item">
                  Blog Grid
                </Link>
                <Link to="/blogDetail" className="dropdown-item">
                  Blog Detail
                </Link>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link
                to=""
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Pages
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/pricingPlan" className="dropdown-item">
                  Pricing Plan
                </Link>
                <Link to="/features" className="dropdown-item">
                  Our features
                </Link>
                <Link to="/team" className="dropdown-item">
                  Team Members
                </Link>
                <Link to="/testimonial" className="dropdown-item">
                  Testimonial
                </Link>
                <Link to="/freeQuote" className="dropdown-item">
                  Free Quote
                </Link>
              </div>
            </div> */}
            <Link
              to="/contact"
              className={cn(
                'nav-item nav-link',
                currentPath.includes('/contact') && 'active'
              )}
            >
              Contact Us
            </Link>
          </div>
          {/* <button
            type="button"
            className="btn text-primary ms-3"
            data-bs-toggle="modal"
            data-bs-target="#searchModal"
          >
            <i className="fa fa-search"></i>
          </button> */}
          <a
            href="/products/Alutech.pdf"
            target="_blank"
            className="btn btn-primary py-2 px-4 ms-3"
          >
            Download Brochure
          </a>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
