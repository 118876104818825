import React, { useContext } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { windowsData, doorsData } from './ProductData.js'
import { ProductContext } from '../products/Products'

const ProductsGrid = () => {
  const data = useContext(ProductContext)
  const isShowWindows = data === 'windows'
  const isShowDoors = data === 'doors'

  return (
    <div className="pb-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="section-title position-relative pb-3 mb-5 mx-auto">
        <h5 className="fw-bold text-primary text-uppercase">
          {isShowWindows ? 'Windows' : 'Doors'}
        </h5>
        <h1 className="mb-0">
          {isShowWindows
            ? 'Variety of styles and colors'
            : 'Smooth and Silent sliding motion'}
        </h1>
      </div>
      <div className="row g-5">
        {isShowWindows &&
          windowsData.map((item, index) => (
            <Product key={item + index} {...item} />
          ))}
        {isShowDoors &&
          doorsData.map((item, index) => (
            <Product
              key={item + index}
              {...item}
              itemsLength={doorsData.length}
            />
          ))}
      </div>
    </div>
  )
}

const Product = ({
  title,
  desc,
  imageUrl,
  technicalSpecifications,
  itemsLength,
}) => (
  <div
    className={cn(
      'wow slideInUp',
      itemsLength < 3 ? 'col-md-6' : 'col-md-6 col-lg-4'
    )}
    data-wow-delay="0.3s"
  >
    <div className="team-item bg-light rounded overflow-hidden">
      <div className="team-img position-relative overflow-hidden">
        <img className="img-fluid w-100" src={imageUrl} alt="" />
      </div>
      <div className="text-center p-4" style={{ minHeight: '160px' }}>
        <h4 className="text-secondry">{title}</h4>
        <p className="m-0">{desc}</p>
      </div>
      <div className="p-4">
        <h5 className="mb-3 text-primary">Technical Specifications</h5>
        <ul className="list-group list-group-flush">
          {technicalSpecifications.map((spec, index) => (
            <li
              key={spec + index}
              className="list-group-item d-flex align-items-center"
            >
              <i className="bi bi-arrow-right text-light-yellow me-3"></i>
              <span className="flex-1">{spec}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

Product.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  technicalSpecifications: PropTypes.array.isRequired,
  itemsLength: PropTypes.number,
}

export default ProductsGrid
