import React from 'react'
import CarouselItem from './CarouselItem'

const Home = () => {
  return (
    <>
      <div className="container-fluid position-relative p-0">
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <CarouselItem
                imageUrl="/img/window1.jpg"
                heading="Elegance"
                subHeading="We Are Innovative"
              />
            </div>
            <div className="carousel-item">
              <CarouselItem
                imageUrl="/img/kitchen3.jpg"
                heading="Excellence"
                subHeading="We provide durable doors and windows"
              />
            </div>
            <div className="carousel-item">
              <CarouselItem
                imageUrl="/img/gate2.jpg"
                heading="Endurance"
                subHeading="We give you enhanced security"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="container-fluid facts py-5 pt-lg-0">
        <div className="container py-5 pt-lg-0">
          <div className="row gx-0">
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: '150px' }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: '60px', height: '60px' }}
                >
                  <i className="fa fa-users text-primary"></i>
                </div>
                <a
                  href="https://g.co/kgs/y2uoDxB"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block ps-4 text-white link-warning link-underline-opacity-0"
                >
                  <h5 className="mb-0">Happy Clients</h5>
                  <h1 className="mb-0" data-toggle="counter-up">
                    100<sup>+</sup>
                  </h1>
                </a>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: '150px' }}
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: '60px', height: '60px' }}
                >
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">Sites Done</h5>
                  <h1 className="mb-0" data-toggle="counter-up">
                    100<sup>+</sup>
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: '150px' }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: '60px', height: '60px' }}
                >
                  <i className="fa fa-award text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Cities Covered</h5>
                  <h1 className="text-white mb-0" data-toggle="counter-up">
                    12
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container pb-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: '1000px' }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Why Choose Us
            </h5>
            <h1 className="mb-0">We turn your dreams into reality</h1>
          </div>
          <div className="row g-5 pt-5">
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: '60px', height: '60px' }}
                  >
                    <i className="fa fa-user-check text-white"></i>
                  </div>
                  <h4>Step 01</h4>
                  <p className="mb-0">
                    Concisely clarify to the client while ensuring complete
                    transparency in a brief, straightforward explanation and
                    give top recommendations.
                  </p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: '60px', height: '60px' }}
                  >
                    <i className="fa fa-stream text-white"></i>
                  </div>
                  <h4>Step 02</h4>
                  <p className="mb-0">
                    Employing top-tier raw materials ensures excellence in our
                    products, surpassing industry standards with superior
                    quality sourcing.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4  wow zoomIn"
              data-wow-delay="0.9s"
              style={{ minHeight: '350px' }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.1s"
                  src="/img/window3.jpg"
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: '60px', height: '60px' }}
                  >
                    <i className="fas fa-industry text-white"></i>
                  </div>
                  <h4>Step 03</h4>
                  <p className="mb-0">
                    Our operational manufacturing facility produces high-quality
                    goods using advanced technology and skilled labor.
                  </p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: '60px', height: '60px' }}
                  >
                    <i className="far fa-window-maximize text-white"></i>
                  </div>
                  <h4>Step 04</h4>
                  <p className="mb-0">
                    Securing a flawless installation process to guarantee
                    functionality and reliability of the implemented system or
                    product.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
