import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const CarouselItem = ({ heading, subHeading, imageUrl }) => {
  return (
    <>
      <img
        style={{
          maxHeight: '700px',
          objectFit: 'cover',
          width: '100%',
        }}
        src={imageUrl}
        alt={`Slide ${imageUrl}`}
      />
      <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
        <div className="p-3" style={{ maxWidth: '900px' }}>
          <h5 className="text-white text-uppercase mb-3 animated slideInDown">
            {heading}
          </h5>
          <h1 className="display-1 text-white mb-md-4 animated zoomIn">
            {subHeading}
          </h1>
          <Link
            to="/contact"
            className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
          >
            Free Quote
          </Link>
          <Link
            to="/products"
            className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
          >
            See Products
          </Link>
        </div>
      </div>
    </>
  )
}

CarouselItem.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default CarouselItem
